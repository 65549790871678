<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <div>
            <h3 class="text-h3 text-typo font-weight-bold ms-4 mb-9">
              {{ $t("ticket.Admin Groups") }}
            </h3>
          </div>
          <div cols="12">
            <v-row>
              <v-col cols="6">
                <v-card color="light">
                  <v-app-bar color="light" class="border-left-primary">
                    <v-toolbar-title>{{ $t("ticket.Groups") }}</v-toolbar-title>
                    <button
                      class="py-5 mb-0 ms-auto"
                      @click="add()"
                      v-if="
                        $store.getters['auth/str_per'].indexOf(
                          'ticket-admin-group-create'
                        ) > -1
                      "
                    >
                      <v-icon class="custome-btn btn-primary my-0"
                        >fas fa-plus</v-icon
                      >
                      <!-- <span class="font-weight-bold text-lg">
                              {{$t('ticket.create new Admin Groups')}}
                            </span>  -->
                    </button>
                  </v-app-bar>
                  <Table
                    :loading="loading"
                    @action="action"
                    :btns="btns"
                    :headers="header"
                    :items="groups"
                  ></Table>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card color="light">
                  <v-app-bar color="light" class="border-left-primary">
                    <v-toolbar-title>{{ $t("ticket.Admins") }}</v-toolbar-title>
                  </v-app-bar>
                  <Table
                    @action="action_admin"
                    :btns="btns_admin"
                    :headers="header_admin"
                    :items="admins"
                  ></Table>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <Modal :data="modal_data"></Modal>
          <!-- <v-card class="card-shadow border-radius-xl">
            <div class="card-padding">
              
            </div>
          </v-card> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/Table.vue";
import Modal from "../Components/New/Modal.vue";
import Notify from "../Components/New/Notify.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("ticket");
export default {
  name: "Admin-Group-Page",
  components: {
    Table,
    Modal,
    Notify,
  },
  data() {
    return {
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      btns_admin: [
        {
          type: "icon",
          text: "destroy_admin",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "ticket-admin-delete",
        },
      ],
      btns: [
        {
          type: "icon",
          text: "show_admin",
          color: "bg-gradient-info",
          icon: "mdi-eye",
        },
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "ticket-admin-group-update",
        },
        {
          type: "icon",
          text: "destroy",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "ticket-admin-group-delete",
        },
      ],
      // admin_data:[],
      header_admin: [
        {
          text: this.$i18n.t("group.Name"),
          value: "users.user",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      header: [
        { text: this.$i18n.t("group.Name"), value: "name", align: "center" },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      form_data: {
        name: "",
        ids: [],
      },
      form_style: [
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("group.Name"),
          error: null,
          value_text: "name",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "autocomplete",
          type_select: "multiple",
          label: this.$i18n.t("ticket.Admins"),
          value_text: "ids",
          items: [],
          title_select: "users.user",
          error: null,
        },
      ],
      list_of_admins: [],
    };
  },
  computed: {
    ...mapState(["groups", "admin_data", "admins"]),
  },
  methods: {
    ...mapActions([
      "getGroupsAdmin",
      "getAdmins",
      "destroyAdmin",
      "destroyGroupAdmin",
    ]),
    get_groups_admin() {
      this.getGroupsAdmin().then(
        (response) => {
          this.list_of_admins = response.data.admins;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit(item);
      } else if (name == "destroy") {
        this.destroy(id);
      } else if (name == "show_admin") {
        this.get_admins(id);
      }
    },
    add() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("ticket.create new Admin Groups");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[1].items = this.list_of_admins;
      this.$store.commit("form/INFO_CALL", {
        name: "ticket/addGroupAdmin",
      });
    },
    edit(item) {
      console.log(item);
      this.modal_data.title =
        this.$i18n.t("ticket.Edit Admin Groups") + " # " + item.name;
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[1].items = this.list_of_admins;
      this.form_data.name = item.name;
      for (let i = 0; i < item.admins.length; i++) {
        const element = item.admins[i];
        this.form_data.ids.push(element.id);
      }
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/SET_DIALOG", true);
      this.$store.commit("form/INFO_CALL", {
        name: "ticket/UpdateGroupAdmin",
        id: item.id,
      });
    },
    destroy(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyGroupAdmin(id).then(
            (response) => {
              // console.log(response);
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
    action_admin(id, name, item) {
      if (name == "destroy_admin") {
        this.destroy_admin(item);
      }
    },
    get_admins(id) {
      this.getAdmins(id);
    },
    destroy_admin(item) {
      console.log(item);
      var data = {
        admin_id: item.pivot.admin_id,
        ticket_admin_group_id: item.pivot.ticket_admin_group_id,
      };
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyAdmin(data).then(
            (response) => {
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_groups_admin();
    document.title = this.$i18n.t("ticket.Admin Groups");
  },
};
</script>
